<template>
  <div class="pdt-cate-page">
	  

    <div class="pdt-main">
      <div class="categorys">
        <van-sidebar v-model="cateIndex">
          <template v-for="(cate, index) in categorys" :key="index">
            <van-sidebar-item  v-if="cate.categoryId=='0c9533a3dc9249dda7eae36347404062'" :title="cate.categoryName" @click="onChange(cate.categoryId)" />
          </template>
        </van-sidebar>
      </div>
      <div class="pdt-list">
        <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
          :pulling-text="SYSLANG.下拉即可刷新"
          :loosing-text="SYSLANG.释放即可刷新"
          :loading-text="SYSLANG.loading"
        >
          <van-list
            v-model:loading="loading"
            :finished="finished"
            :finished-text="SYSLANG.finished"
            :loading-text="SYSLANG.loading"
            @load="getGoodsList"
          >
            <GoodsList :list="goods" />
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
  </div>
  	  <div class="goCart" @click="goCart()">前往購物車</div>
</template>

<script>
import GoodsList from "../components/GoodsList.vue";
import { getCookie,setCookie } from "../util";
import { closeToast, showLoadingToast, showToast } from "vant";

export default {
  components: {
    GoodsList,
  },
  data() {
    return {
      categorys: [],
      cateIndex: 0,
      cateid:"0c9533a3dc9249dda7eae36347404062",
      searchKey: "",
      goods: [],
      refreshing: false,
      finished: false,
      loading: false,
      pagesize: 12,
      curpage: 0,
      pagecount: 0,
      total: 0,
      init: true,
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }

    this.PAGELANG = this.LANG.pages.goods;
    this.SYSLANG = this.LANG.system;

    showLoadingToast({
      message: this.SYSLANG.loading,
      duration: 0,
      forbidClick: true,
    });

    if (this.$route.query.SearchKey) {
      this.searchKey = this.$route.query.SearchKey;
    }

    // 读取分类列表
    this.init = true;
    this.axios.get(this.actions.get_category).then((response) => {
      closeToast();
      let { code, message, data } = response.data;
      //console.log(code, msg, categorys);
      if (code != "200") {
        showToast(message);
      } else {
         this.categorys = data.list;
      }
      this.init = false;
      this.getGoodsList();
    });
  },
  methods: {
	  goCart(){
		  this.$router.replace({
		    path: "/cart",
		  });
	  },
    selectMenu(index) {
      console.log(index);
      this.searchKey = "";
      this.cateid = index;
      window.location.href = "#/product";
      //this.onRefresh();
    },
    changePage(val) {
      this.curpage = val - 1;
      this.goods = [];
      this.getGoodsList();
    },
    getGoodsList() {
      if (this.init) {
        return false;
      }

      console.log("getgoodslist");
      if (this.refreshing) {
        this.refreshing = false;
      }

      this.loading = true;

      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      this.curpage++;
	  
      this.axios
        .get(this.actions.goods_list,{params:{
          // cateid: this.cateid * 1,
		  categoryId: this.cateid,
          page: this.curpage,
		  pageSize:15,
          skuName: this.searchKey,
        }})
        .then((response) => {
          closeToast();
          let { code, msg } = response.data;

          if (code == "200") {
            let { page, pageTotal, total, list } = response.data.data;
            console.log(response)
            this.curpage = page * 1;
            this.pagecount = pageTotal * 1;
            this.total = total * 1;
			
			list=list.filter((item) => item.productId!='b601aaa35f9a4991b9fd2d6b4116890f')
            this.goods = this.goods.concat(list);
          } else {
            showToast({
              message:
                code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          }

          this.loading = false;

          if (this.curpage >= this.pagecount) {
            this.finished = true;
          }
        })
        .catch((error) => {
          //请求失败，触发catch中的函数 可省略
          console.log("views.Product.getGoodsList.error", error);
          closeToast();
          this.curpage--;
          this.getGoodsList();
        });
    },

    // 下拉刷新数据
    onRefresh() {
      this.goods = [];
      this.loading = false;
      this.finished = false;
      this.curpage = 0;

      if (this.categorys.length > 0 && !this.init) {
        this.getGoodsList();
      }
    },

    // 选择分类
    onChange(index) {
      console.log(index, this.cateIndex);
      //this.cateid = this.categorys[index].categoryId;
	  this.cateid=index
	  setCookie("cateid",this.cateid)
      this.searchKey = "";
      this.onRefresh();
    },
  },
  watch: {
    "$route.query"(val) {
      //console.log("Product.$route.query", val);
      if (this.searchKey != val.SearchKey) {
        this.goods = [];
        this.curpage = 0;
        this.searchKey = val.SearchKey;
        this.getGoodsList();
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .pc-pdt-list {
    display: none;
  }
  .pdt-cate-page {
    width: 100vw;
    height: calc(100vh - var(--van-tabbar-height));
    display: flex;
    flex-direction: column;
  }

  .search-block {
    display: block;
    width: 100%;
    height: 50px;
    padding: var(--van-card-padding);
    box-sizing: border-box;
  }
  .search-block .btn-search {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    border: 1px solid #eee;
    font-size: 14px;
    color: #333;
  }
  .search-block .btn-search::before {
    margin-right: 6px;
  }

  .pdt-main {
    display: flex;
    flex-grow: 1;
    height: 0;
    --van-card-price-color: #cfac6b;
  }
  .pdt-main .categorys {
    width: 25vw;
    background-color: #fafafa;
    --van-sidebar-width: 100%;
    --van-sidebar-background: #fafafa;
    --van-sidebar-selected-border-color: var(--van-card-price-color);
  }

  .pdt-main .pdt-list {
    width: 0;
    flex-grow: 1;
    overflow-y: auto;
  }
  .goCart{
	background: #000;
	color: #fff;
	width: 140px;
	margin: auto;
	height: 50px;
	line-height: 50px;
	border-radius: 10px;
	display: flex;
	justify-content: center;
  }
}

@media (min-width: 769px) {
  .pdt-cate-page {
    display: none;
  }
  .pc-pdt-list {
    display: flex;
    padding: calc(100vw / 1920 * 60) calc(100vw / 1920 * 360)
      calc(100vw / 1920 * 70) calc(100vw / 1920 * 360);
    background-color: #f7f7f7;
  }
  .pc-pdt-list .cates {
    width: calc(100vw / 1920 * 280);
    margin-right: calc(100vw / 1920 * 26);
    padding: calc(100vw / 1920 * 24);
    background-color: #fff;
    box-sizing: border-box;
    --el-menu-active-color: var(--van-card-price-color);
    --el-menu-border-color: rgba(0, 0, 0, 0);
    --el-menu-hover-bg-color: #f7f7f7;
  }
  .pc-pdt-list .cates .el-menu-item {
    border-bottom: 1px solid #f7f7f7;
    justify-content: space-between;
  }
  .pc-pdt-list .cates .el-menu-item::after {
    content: "\e68b";
    font-family: "iconfont";
  }
  .pc-pdt-list .pdt-main {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: 0;
    flex-grow: 1;
  }
  .pc-pdt-list .pdt-main >>> .el-pagination {
    --el-fill-color-blank: rgba(0, 0, 0, 0);
    --el-pagination-border-radius: 50%;
    --el-pagination-hover-color: var(--van-card-price-color);
    --el-pagination-button-height: 22px;
    --el-pagination-button-width: 22px;
  }
  .pc-pdt-list .pdt-main >>> .el-pagination .el-pager li {
    margin: 0 3px;
  }
  .pc-pdt-list .pdt-main >>> .el-pagination .el-pager li.is-active {
    background-color: #000;
    color: #fff;
  }
}
</style>